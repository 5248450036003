.invite-title-row {
	display: flex;
	flex-direction: row;
}

.invite-renter-title {
	height: 24px;
	color: #242424;
	font-size: 20px;
	font-weight: bold;
	line-height: 24px;
}

.no-invite-renter-title {
	height: 22px;

	color: #9aa6b6;

	font-size: 20px;
	line-height: 22px;
}

.invite-container {
	height: 140px !important;
	display: flex;
	flex-direction: column;
	justify-content: space-between;
}

.invite-user-checkbox {
	position: unset !important;
	opacity: 1 !important;
	pointer-events: fill !important;
	margin: 5px;
}

.invite-renter-message {
	height: 22px;
	color: #242424;
	margin-bottom: 30px;
	margin-left: 5px;
	font-size: 18px;
	font-weight: bold;
	line-height: 22px;
}

.confirm-property-subtitle {
	text-align: center;
	font-weight: bold;
	margin: 20px;
}

.inspection-create-modal {
	position: absolute;
	top: -10px;
	width: 600px;
	min-height: 380px;
	background-color: white;
	border-radius: 3px;
	z-index: 150;
	left: 50%;
	transform: translate(-50%, 0);
}

.error-message {
	color: red;
}

.inspection-creation-row {
	display: flex;
	flex-direction: row;
	margin-bottom: 20px;
}

.inspection-creation-row > div {
	margin-right: 10px;
	margin-left: 10px;
}

.property-select {
	display: flex;
	flex-direction: column;
	align-items: center;
}

.select-property-titlebar {
	height: 50px;
	width: 540px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	padding: 17px;
	border: 1px solid #e7eef0;
}

.select-property-title {
	color: #9aa6b6;
	font-size: 14px;
	line-height: 16px;
}

.property-select-drop-down-bar {
	width: 540px;
	height: 380px;
	border-radius: 4px;
	background-color: #ffffff;
	box-shadow: 0 2px 10px 0 rgba(0, 0, 0, 0.2);
	position: absolute;
	top: 153px;
	padding: 20px;
}

.property-select-drop-down-bar-list {
	height: 285px;
	overflow: scroll;
}

.property-select-drop-down-bar-list::-webkit-scrollbar {
	display: none;
}

.property-select-top-row {
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.add-property-button {
	height: 50px;
	width: 160px;
	border-radius: 8px !important;
	background-color: #3d50ff !important;
	color: #ffffff;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	text-align: center;
	margin-left: 20px;
}

.property-select-dropdown-item {
	height: 50px;
	width: 500px;
	border-radius: 2px 2px 0 0;
	background-color: #ffffff;
	border-bottom: 1px solid #bfbfbf;
	padding: 14px;
}

.close-modal {
	margin-left: 370px !important;
}

.inspection-list-datepicker {
	margin-left: 1rem !important;
	margin-right: 1rem !important;
	width: 45%;
}

/* Inspection Detail */

.inspection-detail-content {
	display: flex;
	flex-basis: row;
	width: 100%;
}

.inspection-detail-inprogress {
	display: flex;
	flex-direction: column;
	align-items: center;
	height: calc(100vh - 360px);
	width: 100%;
	padding-top: 20px;
	border-left: 1px solid #dee2e7;
}

.inspection-detail-inprogress-title {
	color: #242424;
	font-size: 24px;
	font-weight: bold;
	line-height: 26px;
	text-align: center;
	margin-bottom: 20px;
}

.inspection-detail-inprogress-subtitle {
	height: 32px;
	width: 430px;
	color: #9aa6b6;
	font-size: 14px;
	line-height: 16px;
	text-align: center;
}

.room-list-title {
	color: #242424;
	font-size: 16px;
	line-height: 18px;
	text-align: center;
	padding: 20px;
}

.feature-view-container {
	display: flex;
	flex-direction: column;
	margin-top: 10px;
	padding-bottom: 20px;
	border: 1px solid red;
}

.feature-view-content {
	display: flex;
	flex-direction: row;
	padding-bottom: 15px;
}

.feature-view-row-1 {
	padding-left: 60px;
	width: 65%;
	border: 1px solid red;
}

.feature-view-row-2 {
	width: 17%;
	display: flex;
	align-items: center;
	flex-direction: column;
	border: 1px solid red;
}

.feature-title {
	color: #242424;
	font-size: 18px;
	font-weight: bold;
	line-height: 22px;
	padding-top: 8px;
}

.feature-question {
	padding-left: 20px;
	border: 1px solid red;
}

.feature-answer {
	padding-top: 10px;
	border: 1px solid red;
}

.feature-rating-Fair {
	height: 30px;
	width: 57px;
	border-radius: 8px;
	background-color: #ff954b;
	text-align: center;
	font-weight: bold;
	color: white;
	line-height: 32px;
}

.feature-rating-Good {
	height: 30px;
	width: 57px;
	border-radius: 8px;
	background-color: #4adea9;
	text-align: center;
	font-weight: bold;
	color: white;
	line-height: 32px;
}
.feature-rating-Poor {
	height: 30px;
	width: 57px;
	border-radius: 8px;
	background-color: #fe5c6c;
	text-align: center;
	font-weight: bold;
	color: white;
	line-height: 32px;
}

.feature-rating-skipped {
	height: 30px;
	width: 57px;
	border-radius: 8px;
	border: 2px solid rgb(154, 166, 182);
	background-color: white;
	text-align: center;
	font-weight: bold;
	color: rgb(154, 166, 182);
	font-size: 12px;
	line-height: 25px;
}

.feature-photos-number {
	color: #3d50ff;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	text-align: right;
}
.feature-photos-number-disabled {
	color: #979797;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	text-align: right;
	padding: 5px;
}

.feature-view-row-3 {
	display: flex;
	flex-direction: row;
	justify-content: flex-end;
	width: 15%;
	padding-right: 15px;
	border: 1px solid red;
}

.feature-notes-container {
	margin-left: 80px;
	padding-top: 10px;
}

.feature-notes-title {
	color: #9aa6b6;
}

.inspection-detail-title-row {
	display: flex;
	justify-content: space-between;
	padding-top: 15px;
	padding-left: 15px;
	padding-right: 15px;
	padding-bottom: 7.5px;
	border-bottom: 1px solid #bfbfbf;
}

.detail-title-container {
	margin-left: 20px;
}

.inspection-detail-button-delete {
	height: 45px;
	width: 10rem;
	color: #979797;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	text-align: center;
	margin-right: 10px !important;
	padding: 10px;
	border-radius: 15px;
	background-color: #fafafa;
	user-select: none; /* supported by Chrome and Opera */
	-webkit-user-select: none; /* Safari */
	-khtml-user-select: none; /* Konqueror HTML */
	-moz-user-select: none; /* Firefox */
	-ms-user-select: none; /* Internet Explorer/Edge */
}

.inspection-detail-button-edit {
	height: 45px;
	width: 5rem;
	border-radius: 8px;
	background-color: #15212b;
	color: #ffffff;
	font-size: 14px;
	font-weight: bold;
	line-height: 16px;
	text-align: center;
	padding: 10px;
}
.inspection-info-content {
	width: 80vw;
	background-color: #fafafa;
	min-height: 80vh;
	border: 1px solid #d9d7d7;
	border-radius: 3px;
	-webkit-box-shadow: 0 0 1px #979797;
	box-shadow: 0 0 1px #979797;
	position: relative;
}

.remove-bottom-border div input {
	border-bottom: none !important;
}

.inspection-detail-subheader-row {
	display: flex;
	flex-direction: row;
}

.inspection-detail-subheader-title {
	color: #9aa6b6;
}

.inpsection-download-button {
	width: 127px;
	height: 45px;
	color: white;
	background-color: #3d50ff;
	border-radius: 6px;
	margin-top: 0rem;
	font-weight: bold;
	border: none;
}

.inspection-detail-property-invite-row {
	background-color: #ebeff3;
	height: 80px;
	display: flex;
	justify-content: space-between;
	flex-direction: row;
	width: 100%;
	padding: 16px;
	border-bottom: 1px solid #bfbfbf;
}

.inspection-detail-property-info {
	margin-left: 20px;
	z-index: 1;
}

.inspection-detail-property-city-zip {
	color: #3d50ff;
	font-weight: bold;
	font-size: 14px;
}

.inspection-detail-property-address {
	font-size: 18px;
	font-weight: bold;
}

.inspection-detail-invite {
	margin-left: 150px;
}

.inspection-detail-email {
	font-size: 14px;
	font-weight: bold;
}

/* Mobile Inspection Detail */

.mobile-inspection-detail-view {
	width: 100%;
	background-color: #ffffff;
	height: calc(100vh - 56px);
}

.mobile-inspection-detail-title-bar {
	display: flex;
	justify-content: space-between;
	border-top: 1px solid #979797;
	padding: 10px;
}

.mobile-inspection-detail-title-header {
	font-size: 20px;
	font-weight: bold;
	text-align: end;
}

.mobile-inspection-detail-title-property-address {
	font-size: 16px;
}

.mobile-inspection-detail-title-property-sub-address {
	color: #a5b0bf;
}

.mobile-inspection-detail-subtitle-bar {
	padding: 10px;
	border-bottom: 1px solid #dee2e7;
}

.mobile-inspection-detail-subtitle-row {
	display: flex;
	flex-direction: row;
	margin-bottom: 5px;
}

.mobile-inspection-detail-subtitle-title {
	color: #a5b0bf;
}

.mobile-inspection-detail-subtitle-value {
	padding-left: 5px;
}

.mobile-inspection-detail-navbar {
	display: flex;
	flex-direction: row;
	overflow: auto;
	height: 40px;
	border-bottom: 1px solid #a5b0bf;
	box-shadow: 0 3px 2px 0px gray;
}

.mobile-room-navbar-title {
	color: #a5b0bf;
	width: 104px;
	height: 100%;
	text-align: center;
	vertical-align: middle;
	flex: 0 0 auto;
}

.mobile-room-navbar-title-selected {
	width: 104px;
	height: 100%;
	text-align: center;
	vertical-align: middle;
	flex: 0 0 auto;
	color: #3d50ff;
	font-weight: bold;
	border-bottom: 1px solid #3d50ff;
}

.mobile-inspection-detail-room-Detail-title {
	font-size: 20px;
	font-weight: bold;
	padding: 5px;
	padding-left: 10px;
}

.mobile-feature-view-container {
	min-height: 170px;
	border-bottom: 1px solid #a5b0bf;
	background-color: #ffffff;
}

.mobile-feature-title-row {
	display: flex;
	justify-content: space-between;
	padding: 5px;
	padding-left: 12px;
}

.mobile-feature-question-row {
	display: flex;
	justify-content: space-between;
	padding: 5px;
	padding-left: 12px;
	padding-right: 25px;
}
.feature-question-row {
	display: flex;
	justify-content: space-between;
	padding: 5px;
	padding-left: 10%;
	padding-right: 25%;
	border: 1px solid red;
}

.mobile-feature-question {
	width: 83%;
	border: 1px solid red;
}

.mobile-feature-title {
	font-weight: bold;
	font-size: 16px;
}

.mobile-feature-notes-title {
	color: #a5b0bf;
}

.mobile-feature-notes-container {
	padding: 10px;
	padding-left: 12px;
}

.mobile-feature-notes-info {
	font-weight: bold;
}

.feature-photos-number {
	padding: 5px;
}

.mobile-room-list {
	overflow: scroll;
}

.mobile-room-list-item {
	height: 60px;
	background-color: #ffffff;
	display: flex;
	flex-direction: row;
	padding: 20px;
	padding-left: 35px;
	border: 1px solid #dee2e7;
	border-top: none;
	border-left: none;
	border-right: none;
}

.mobile-room-list-title-card {
	display: flex;
	flex-direction: column;
	justify-content: center;
	padding-left: 10px;
}

.mobile-room-list-room-title {
	color: #242424;
	font-size: 16px;
	line-height: 18px;
	font-weight: bold;
}

.mobile-room-list-step {
	color: #9aa6b6;
	font-size: 12px;
	line-height: 15px;
}

.mobile-room-list-logo {
	height: 30px;
	width: 30px;
}

.mobile-delete-inspection-container {
	border-radius: 25px;
	display: flex;
	justify-content: center;
	align-items: center;
	height: 50px;
	width: 50px;
	margin: 10px;
	margin-left: 15px;
	background-color: #ffffff;
	box-shadow: 0px 2px 4px;
}

/* Image Modal */

.mobile-feature-detail {
	width: 100%;
	padding: 20px;
	background-color: white;
	display: flex;
	flex-direction: column;
	align-items: center;
}

.image-mobile-header {
	margin-bottom: 20px;
	width: 100%;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
}

.image-mobile-title-container {
	display: flex;
	flex-direction: column;
}

.mobile-footer-image-selected {
	opacity: 1;
	height: 166.88px;
	width: 100px;
	margin: 10px;
}

.image-mobile-title {
	color: #242424;
	font-size: 28px;
	font-weight: bold;
	line-height: 32px;
}

.image-mobile-subtitle {
	color: #9aa6b6;
	font-size: 14px;
	line-height: 16px;
}

.image-mobile-display-image {
	height: 475px;
}

.image-mobile-display-right {
	position: absolute;
	height: 70px;
	width: 70px;
	right: -106px;
	top: 300px;
}
.image-mobile-display-left {
	position: absolute;
	height: 70px;
	width: 70px;
	left: -106px;
	top: 300px;
}

.image-mobile-footer {
	display: flex;
	flex-direction: row;
	overflow: scroll;
	width: 100vw;
}

.mobile-footer-image {
	height: 166.88px;
	margin: 10px;
	opacity: 0.5;
}

.mobile-footer-image-selected {
	height: 166.88px;
	margin: 10px;
}

.mobile-feature-exit {
	height: 20px;
	width: 20px;
}
.inspection-detail-subheader-value {
	padding-left: 5px;
	padding-right: 5px;
}
