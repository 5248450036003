html,
body {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  position: relative;
  float: none;
  background-color: #ebeff3;
}

body {
  font-family: 'Poppins' !important;
}

a {
  text-decoration: none;
}


#grey-area {
  height: 100%;
  width: 100%;
  opacity: 0.7;
  position: fixed;
  background-color: rgba(31, 39, 50, 0.92);
  top: 0;
  z-index: 100;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.breakline {
  box-sizing: border-box;
  height: 2px;
  width: 100%;
  border: 1px solid #dee2e7;
}

.full-width-centered {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.full-width {
  width: 100%;
}

.content {
  width: 100%;
  background-color: #fafafa;
  margin-top: 50px !important;
  min-height: 80vh;
  border: 1px solid #d9d7d7;
  border-radius: 3px;
  -webkit-box-shadow: 0 0 1px #979797;
  box-shadow: 0 0 1px #979797;
  position: relative;
}

.blend {
  background-color: #ebeff3;
}

.back-button-container {
  padding-top: 20px;
  padding-bottom: 20px;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.back-button-text {
  color: #979797 !important;
  font: 14px;
  padding-left: 10px;
}

/* Nav Bar */

.nav-bar-link {
  color: black;
}

.nav-bar-selected {
  border-bottom: 2px solid #3d50ff;
}

/* Dashboard */

.dashboard {
  position: relative;
  display: flex;
  justify-content: center;
  width: 100%;
  padding-bottom: 20px;
}

.dashboard-list {
  width: 100%;
  padding: 1rem;
}

.dashboard-list-item {
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid rgba(215, 219, 224);
  color: black;
  cursor: pointer;
}

.list-title-bar {
  display: flex;
  align-items: row;
  height: 100px;
  align-items: center;
}

.list-title-bar-title {
  width: 80%;
  color: black;
  font: 'Product-Sands-Bold';
  font-weight: bold;
  font-size: 26px;
  margin-left: 2%;
  line-height: 1.25;
  padding-bottom: 0.75rem;
}

.list-search-bar {
  margin-right: 2rem !important;
  margin-left: 1rem !important;
  width: 45% !important;
}

.list-title-bar-button {
  width: 230px;
  height: 45px;
  color: white;
  background-color: #3d50ff;
  border-radius: 6px;
  margin-top: 0rem;
  font-weight: bold;
}

.dashboard-list-category-bar {
  background-color: rgba(231, 235, 240);
  color: #979797;
}

.dashboard-list-item-address {
  width: 19%;
  padding-left: 20px;
  font-size: 13.5px;
}

.dashboard-list-item-city {
  width: 11%;
  font-size: 13.5px;
}

.dashboard-list-item-zip {
  width: 12%;
  font-size: 13.5px;
}

.dashboard-list-item-type {
  width: 13%;
  font-size: 13.5px;
  margin-left: 2%;
}

.dashboard-list-item-added {
  width: 15%;
  padding-right: 20px;
  font-size: 13.5px;
}

.inspection-display-title {
  font-size: 2.5rem;
  text-align: center;
}

#inspection-display-content {
  width: 100vw;
  height: 100%;
  position: relative;
}

.nav-direction-logo {
  width: 10px;
  height: 10px;
  margin-left: 5px;
}

.flipped {
  -webkit-transform: rotate(180deg);
  -moz-transform: rotate(180deg);
  -ms-transform: rotate(180deg);
  -o-transform: rotate(180deg);
  transform: rotate(180deg);
}

/* Create Modal */

.create-modal {
  position: absolute;
  top: -10px;
  width: 600px;
  background-color: white;
  border-radius: 3px;
  z-index: 150;
}

.property-detail-modal {
  left: 50%;
  transform: translate(-50%, 0);
}

.hidden {
  display: none;
}

.create-header-row {
  padding: 10px;
  padding-left: 20px;
  margin-bottom: 20px;
}

.create-header {
  font-size: 30px;
  font-weight: bold;
}

.create-subheader {
  font-size: 12px;
  color: #9aa6b5;
}

.create-step-row {
  display: flex;
  flex-direction: row;
  height: 46px;
  border-bottom: 1px solid #dee2e8;
}

.create-step-row-item {
  width: 25%;
  justify-content: center;
  text-align: center;
  color: #9aa6b5;
}

.create-input-text {
  background-color: #ebeff3 !important;
  border: none !important;
  padding: 8px !important;
  padding-left: 15px !important;
}

.room-number-input {
  -webkit-appearance: none !important;
  margin: 0;
  margin-left: 10px !important;
  margin-right: 10px !important;
}

.create-input-text:focus {
  -webkit-box-shadow: -7px 7px 6px -7px rgba(170, 170, 170, 1) !important;
  -moz-box-shadow: -7px 7px 6px -7px rgba(170, 170, 170, 1) !important;
  box-shadow: -7px 7px 6px -5px rgba(170, 170, 170, 1) !important;
}

.create-input-content {
  padding: 15px;
  border-bottom: 1px solid #dee2e8;
}

.create-input-content-centered {
  padding: 15px;
  border-bottom: 1px solid #dee2e8;
  widows: 100%;
  justify-content: center;
  text-align: center;
}

.create-input-title {
  margin-bottom: 10px;
  font-weight: bold;
  font-size: 20px;
}

.create-input-subtitle {
  font-size: 12px;
  color: #9aa6b5;
  margin-bottom: 20px;
}

.create-input-row {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.zipcode-input {
  width: 180px !important;
}

.city-input {
  width: 90% !important;
  margin-right: 8px !important;
}

.create-input-button {
  background-color: #ebeff3 !important;
  color: black !important;
  text-align: center !important;
  display: flex;
  align-items: center;
  height: 60px !important;
  padding-left: 30px !important;
  padding-right: 30px !important;
  border-radius: 3px !important;
}

.created-input-button-selected {
  background-color: #3d50ff !important;
  color: white !important;
}

.created-input-lease-selected {
  background-color: #3d50ff !important;
  color: white !important;
}

.create-input-footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 15px;
}

.create-input-next {
  background-color: #3d50ff;
  height: 49px;
  width: 106px;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.create-input-disabled {
  background-color: #9aa6b6;
  height: 49px;
  width: 106px;
  border-radius: 5px;
  color: white;
  font-size: 15px;
  font-weight: bold;
}

.create-input-cancel {
  background-color: white;
  border: none;
  height: 49px;
  width: 106px;
  border-radius: 5px;
  color: #3d50ff;
  font-size: 15px;
  font-weight: bold;
}

.create-input-grey-title-bar {
  background-color: #ebeff3;
  height: 40px;
  font-size: 14px;
  line-height: 17px;
  font-weight: 500;
  color: #9aa6b6;
  padding: 12px;
}

.create-checkbox-toggle {
  position: relative !important;
  opacity: 1 !important;
  pointer-events: fill !important;
}

.create-input-toggle-container {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  height: 40px;
}

.create-checkbox-title {
  height: 23px;
  color: #242424;
  font-size: 19px;
  font-weight: bold;
  line-height: 23px;
  padding: 9px;
}

.create-checkbox-title-unchecked {
  height: 23px;
  color: #9aa6b6;
  font-size: 19px;
  line-height: 23px;
  padding: 9px;
}

.create-checkbox-toggle {
  margin: 10px;
}

.creation-confirmation-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 480px;
  padding-bottom: 30px;
}

.creation-confirmation-title {
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
  margin-top: 40px;
  margin-bottom: 20px;
}

.confirm-property-image {
  height: 250.99px;
  width: 210px;
}

.confirm-property-address {
  color: #9aa6b6;
  font-size: 16px;
  letter-spacing: -0.38px;
  line-height: 18px;
}

.creation-confirmation-subtitle {
  color: #242424;
  font-size: 20px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 20px;
}

.create-confirmation-footer {
  display: flex;
  width: 80%;
  justify-content: space-between;
}

.flex-end {
  justify-content: flex-end !important;
}

/* Loading Animation */

.loading {
  margin-top: 100px;
  height: 100vh;
  width: 100vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 51px;
  height: 51px;
  margin: 6px;
  border: 6px solid black;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: black transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.auth-error-message {
  font-weight: bold !important;
  margin-top: 10px !important;
  font-size: 14px !important;
}

.display-none {
  display: none;
}

.feature-list-container>ul:nth-child(odd) {
  border: 2px solid brown;
}

@media print {
  .page-break {
    display: block;
    width: 100%;
    height: 300px;
  }

  .green-response {
    color: green;
    -webkit-print-color-adjust: exact;
  }

  .yellow-response {
    color: orange;
    -webkit-print-color-adjust: exact;
  }

  .red-response {
    color: red;
    -webkit-print-color-adjust: exact;
  }

  .selected-response-no {
    background-color: red;
    color: black;
    -webkit-print-color-adjust: exact;
  }

  .selected-response-yes {
    background-color: green;
    color: black;
    -webkit-print-color-adjust: exact;
  }
}

/* Property Detail */

.detail-title-row {
  display: flex;
  justify-content: space-between;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
  padding-bottom: 7.5px;
  border-bottom: 1px solid #bfbfbf;
  align-items: center;
  width: 100%;
}

.detail-title-subheader {
  color: #242424;
  font-weight: bold;
  line-height: 32px;
  color: #3d50ff;
}

.detail-title-header {
  color: #242424;
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
}

.detail-button-edit {
  height: 50px;
  width: 141px;
  border-radius: 8px;
  background-color: #3d50ff;
  box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.2);
}

.detail-content-container {
  display: flex;
  flex-direction: row;
}

.empty-search-message {
  position: absolute;
  height: 46px;
  width: 400px;
  color: #9aa6b6;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  left: 390px;
  top: 300px;
  font-size: 20px;
}

.empty-list {
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.empty-properties-list-image {
  height: 273.56px;
  width: 450px;
  margin-bottom: 40px;
}

.empty-list-title {
  height: 26px;
  width: 197px;
  color: #242424;
  font-size: 24px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  margin-bottom: 20px;
}

.empty-list-title-inspection {
  height: 26px;
  width: 197px;
  color: #242424;
  font-size: 24px;
  font-weight: bold;
  line-height: 26px;
  text-align: center;
  margin-bottom: 40px;
}

.empty-list-subtitle {
  height: 46px;
  width: 400px;
  color: #9aa6b6;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
}

.empty-list-subtitle-inspection {
  height: 46px;
  width: 400px;
  color: #9aa6b6;
  font-size: 14px;
  line-height: 16px;
  text-align: center;
  margin-bottom: 50px;
}

.list-empty-button {
  box-sizing: border-box;
  height: 51px;
  width: 134px;
  border: 1px solid #979797;
  color: white;
  background-color: #3d50ff;
  border-radius: 4px;
}

/* Image Modal */
.image-modal {
  position: fixed;
  width: 80% !important;
  top: 20px;
  left: 50%;
  transform: translate(-50%, 0);
  padding: 20px;
  background-color: white;
  border-radius: 3px;
  z-index: 200;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.image-modal-header {
  margin-bottom: 20px;
  width: 100%;
}

.image-modal-title-container {
  display: flex;
  flex-direction: column;
}

.image-modal-footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.footer-image {
  height: 166.88px;

  margin: 10px;
  opacity: 0.5;
}

.footer-image-selected {
  opacity: 1;
  height: 166.88px;
  width: 100px;
  margin: 10px;
}

.image-modal-title {
  color: #242424;
  font-size: 28px;
  font-weight: bold;
  line-height: 32px;
}

.image-modal-subtitle {
  color: #9aa6b6;
  font-size: 14px;
  line-height: 16px;
}

.image-modal-display-image {
  height: 475px;
}

.image-modal-display-right {
  position: absolute;
  height: 70px;
  width: 70px;
  right: -106px;
  top: 300px;
}

.image-modal-display-left {
  position: absolute;
  height: 70px;
  width: 70px;
  left: -106px;
  top: 300px;
}

.material-reset {
  border: 0 !important;
  margin: 0 !important;
  padding: 0 !important;
  background: transparent !important;
  transition: none !important;
  box-sizing: border-box !important;
  box-shadow: none !important;
}

.material-reset:focus {
  box-shadow: none !important;
  outline: none !important;
}

.MuiDialogRoot,
.MuiDialog-root,
.MuiModal-root,
.MuiTooltip-popper {
  z-index: 999999999 !important;
}

.intercom-lightweight-app {
  z-index: 99999999 !important;
}

nav,
nav .nav-wrapper i,
nav a.sidenav-trigger,
nav a.sidenav-trigger i {
  line-height: 56px !important;
}

nav ul a:hover {
  background-color: transparent !important;
}

nav ul a:not(.nav-bar-selected):hover {
  opacity: 0.6;
  transition: 0.2s opacity ease-in-out;
}

.login-button {
  box-shadow: none !important;
}

.form-control {
  width: 300px !important;
  padding-left: 48px !important;
}

.Calendar {
  box-shadow: none !important;
}

[role='dialog'] {
  z-index: 9999999999 !important;
}

input::-webkit-calendar-picker-indicator {
  display: none;
}

input[type="date"]::-webkit-input-placeholder {
  visibility: hidden !important;
}