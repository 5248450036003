.react-multi-email {
	margin: 0;
	max-width: 100%;
	-webkit-box-flex: 1;
	-ms-flex: 1 0 auto;
	flex: 1 0 auto;
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0);
	text-align: left;
	line-height: 1.21428571em;
	padding-top: 12px;
	padding-bottom: 0px;
	padding-left: 12px;
	padding-right: 12px;
	background: #fff;
	color: rgba(0, 0, 0, 0.87);
	-webkit-transition:
		box-shadow 0.1s ease,
		outline 0.1s ease;
	transition:
		box-shadow 0.1s ease,
		outline 0.1s ease;
	font-size: 14px;
	position: relative;
	display: flex;
	flex-wrap: wrap;
	align-items: center;
	align-content: flex-start;
	background-color: #ECECEC;
	border: none;
	border-bottom: 1px solid gray;
	border-radius: 4px 4px 0 0;
	box-sizing: border-box;
}

.react-multi-email:focus-within::after {
	content: '';
	height: 2px;
	background-color: #3d50ff;
	width: 100%;
	position: absolute;
	bottom: -1px;
	left: 0;
	transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.react-multi-email.error:focus-within::after {
	content: '';
	height: 2px;
	background-color: #d32f2f;
	width: 100%;
	position: absolute;
	bottom: -1px;
	left: 0;
	transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.react-multi-email.error::after {
	content: '';
	height: 2px;
	background-color: #d32f2f;
	width: 100%;
	position: absolute;
	bottom: -1px;
	left: 0;
	transition: transform 200ms cubic-bezier(0.0, 0, 0.2, 1) 0ms;
}

.react-multi-email > span[data-placeholder] {
	display: none;
	position: absolute;
	left: 0.5em;
	top: 0.4em;
	padding: 0.4em;
	line-height: 1.21428571em;
	
}



.react-multi-email.empty > span[data-placeholder] {
	display: inline;
	color: #a6a6a6;
	font-family: 'Roboto';
	font-weight: 400;
	font-size: 16px;
	margin-top: 6px;
}

.react-multi-email.fill > span[data-placeholder] {
	display: none;
}

.react-multi-email > input {
	height: 32px;
	width: auto !important;
	outline: none !important;
	border: 0 none !important;
	display: inline-block !important;
	line-height: 1;
	vertical-align: baseline !important;
	padding: 0;
	background-color: #ECECEC !important;
	font-family: Roboto;
	font-weight: 400;
	font-size: 1rem;
	line-height: 1.4375em;
	color: rgba(0, 0, 0, 0.87);
	margin-bottom: 7px;
}

.react-multi-email [data-tag] {
	line-height: 1;
	vertical-align: baseline;
	margin: 0.14285714em;
	background-color: #f3f3f3;
	background-image: none;
	padding: 0.5833em 0.833em;
	color: rgba(0, 0, 0, 0.6);
	text-transform: none;
	font-weight: 600;
	border: 0 solid transparent;
	border-radius: 0.28571429rem;
	-webkit-transition: background 0.1s ease;
	-o-transition: background 0.1s ease;
	transition: background 0.1s ease;
	font-size: 14px;
	display: flex;
	align-items: center;
	justify-content: flex-start;
	max-width: 100%;
}
.react-multi-email [data-tag] [data-tag-item] {
	max-width: 100%;
	overflow: hidden;
}
.react-multi-email [data-tag]:first-child {
	margin-left: 0;
}
.react-multi-email [data-tag] [data-tag-handle] {
	margin-left: 0.833em;
	cursor: pointer;
}
